<template>
  <div>
    <div
      ref="refTop"
      style="scroll-margin-top: 60px;"
    />
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <b-sidebar
        id="sidebar-add-new-event"
        sidebar-class="sidebar-lg"
        :visible="isEventHandlerSidebarActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      >
        <template #default="{ hide }">
          <div v-if="event.type === 'Booking'">
            <div
              class="navbar-fixed-top"
              :style="{
                top: scrolledTo ? '0px' : null,
                'padding-top': scrolledTo ? '0px' : null,
                'z-index': scrolledTo ? '997' : '996',
              }"
            >
              <div
                style="height: 3px; width: 100%; align-items: center; text-align: center; z-index: 999; position: fixed; top: 0;"
                :style="(event.status_color !== undefined)? `background-color: ${iconColor(event.status_color)} !important;` :
                  `background-color: ${iconColor('secondary')} !important;`"
              />
              <!-- Nav Bar -->
              <van-nav-bar
                class="p-0 m-0"
                :title="`${$t('Event')} » ${$t(event.type)}`"
              >
                <!-- Nav Bar :: Left -->
                <template #left>
                  <b-button
                    variant="outline-primary"
                    @click.stop="hide"
                  >
                    <feather-icon
                      icon="CornerDownRightIcon"
                      class="v-align-middle"
                    />
                    {{ $t('Close') }}
                  </b-button>
                </template>
                <!-- Nav Bar :: Right -->
                <template #right>
                  <div
                    v-if="event.picture_url !== undefined && event.picture_url !== ''"
                    @click.prevent="onLineUserProfile(event)"
                  >
                    <b-avatar
                      :src="event.picture_url"
                      size="32px"
                      variant="light-primary"
                      badge
                      class="badge-minimal"
                      badge-variant="success"
                    />
                  </div>
                </template>
              </van-nav-bar>

              <HeaderProductDate :header-data="event" />
            </div>

            <!-- Detail -->
            <div style="padding-top: 220px; padding-bottom: 80px; width: 100%;">
              <van-divider
                class="p-0 m-0 mx-1 my-1"
                :style="{ color: '#888888', borderColor: '#888888' }"
              >
                {{ $t('Detail') }}
              </van-divider>
              <BookingBodyDetail :body-data="event" />
              <van-divider
                class="p-0 m-0 mx-1 my-25"
                :style="{ color: '#888888', borderColor: '#888888' }"
              >
                {{ $t('Summary') }}
              </van-divider>
              <BookingBodySummary :body-data="event" />
              <van-divider
                class="p-0 m-0 mx-1 my-25"
                :style="{ color: '#888888', borderColor: '#888888' }"
              >
                {{ $t('PageBooking.Title.Booking Time') }}
              </van-divider>
              <BookingBodySlotTime :body-data="event" />
            </div>
          </div>
          <div v-if="event.type === 'Renting'">
            <!-- Header Fixed -->
            <div
              class="navbar-fixed-top"
              :style="{
                top: scrolledTo ? '0px' : null,
                'padding-top': scrolledTo ? '0px' : null,
                'z-index': scrolledTo ? '997' : '996',
              }"
            >
              <!-- Top Line Color -->
              <div
                style="height: 3px; width: 100%; align-items: center; text-align: center; z-index: 999; position: fixed; top: 0;"
                :style="(event.status_color !== undefined)? `background-color: ${iconColor(event.status_color)} !important;` :
                  `background-color: ${iconColor('secondary')} !important;`"
              />
              <!-- Nav Bar -->
              <van-nav-bar
                class="p-0 m-0"
                :title="`${$t('Event')} » ${$t(event.type)}`"
              >
                <!-- Nav Bar :: Left -->
                <template #left>
                  <b-button
                    variant="outline-primary"
                    @click.stop="hide"
                  >
                    <feather-icon
                      icon="CornerLeftDownIcon"
                      class="v-align-middle"
                    />
                    {{ $t('Close') }}
                  </b-button>
                </template>
                <!-- Nav Bar :: Right -->
                <template #right>
                  <div
                    v-if="event.picture_url !== undefined && event.picture_url !== ''"
                    @click.prevent="onLineUserProfile(event)"
                  >
                    <b-avatar
                      :src="event.picture_url"
                      size="32px"
                      variant="light-primary"
                      badge
                      class="badge-minimal"
                      badge-variant="success"
                    />
                  </div>
                </template>
              </van-nav-bar>

              <HeaderProductDate :header-data="event" />
            </div>

            <!-- Detail -->
            <div style="padding-top: 220px; padding-bottom: 80px; width: 100%;">
              <van-divider
                class="p-0 m-0 mx-1 my-1"
                :style="{ color: '#888888', borderColor: '#888888' }"
              >
                {{ $t('Detail') }}
              </van-divider>
              <RentingBodyDetail :body-data="event" />
              <van-divider
                class="p-0 m-0 mx-1 my-25"
                :style="{ color: '#888888', borderColor: '#888888' }"
              >
                {{ $t('Summary') }}
              </van-divider>
              <RentingBodySummary :body-data="event" />
            </div>
          </div>

          <!-- Footer Approve/Disapprove -->
          <b-card
            v-if="event.approve_status === 'wait_approve' && $can('write', 'ModuleBooking.Calendar')"
            class="w-100 h-auto p-0 m-0"
            no-body
            style="position: fixed; bottom: 0;"
          >
            <b-row class="py-1 px-75">
              <b-col
                cols="6"
                class="pr-25"
              >
                <b-button
                  class="w-100"
                  variant="outline-warning"
                  @click="onDisapprove(event)"
                >
                  <feather-icon icon="XIcon" />
                  {{ $t('Disapprove') }}
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="pl-25"
              >
                <b-button
                  class="w-100"
                  variant="success"
                  @click="onApprove(event)"
                >
                  <feather-icon icon="CheckIcon" />
                  {{ $t('Approve') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>

          <!-- Footer Payment -->
          <b-card
            v-if="event.payment_status !== 'wait_approve' && event.payment_status === 'wait_payment' && $can('write', 'ModuleBooking.Calendar')"
            class="w-100 h-auto p-0 m-0"
            no-body
            style="position: fixed; bottom: 0;"
          >
            <b-row class="py-1 px-75">
              <b-col
                cols="6"
                class="pr-25"
              >
                <b-button
                  class="w-100"
                  variant="outline-info"
                  @click="onInformPayment(event)"
                >
                  <feather-icon icon="MessageSquareIcon" />
                  {{ $t('Inform Payment') }}
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="pl-25"
              >
                <b-button
                  class="w-100"
                  variant="success"
                  @click="onProcessPayment(event)"
                >
                  <feather-icon icon="CreditCardIcon" />
                  {{ $t('Process Payment') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-sidebar>
    </b-overlay>

    <!-- Form -->
    <payment-form
      v-model="shallShowPaymentFormModal"
      :payment-data="paymentData"
      :payment-file="paymentFile"
      :type-payment-form="typePaymentForm"
      @payment-form-update="updatePaymentForm"
      @discard-payment-form="discardPaymentForm"
    />

  </div>
</template>

<script>
import {
  BSidebar,
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaymentForm from '@/views/booking/payment/PaymentForm.vue'
import HeaderProductDate from './component/HeaderProductData.vue'
import BookingBodyDetail from './component/BookingBodyDetail.vue'
import BookingBodySummary from './component/BookingBodySummary.vue'
import BookingBodySlotTime from './component/BookingBodySlotTime.vue'
import RentingBodyDetail from './component/RentingBodyDetail.vue'
import RentingBodySummary from './component/RentingBodySummary.vue'

export default {
  components: {
    BSidebar,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,

    HeaderProductDate,
    BookingBodyDetail,
    BookingBodySummary,
    BookingBodySlotTime,
    RentingBodyDetail,
    RentingBodySummary,

    PaymentForm,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,

      // payment form
      shallShowPaymentFormModal: false,
      typePaymentForm: '',
      paymentData: {},
      paymentFile: {},
    }
  },
  methods: {
    scrollToTop() {
      this.$refs.refTop.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    onDisapprove(item) {
      console.log(item)
    },
    onApprove(item) {
      console.log(item)
    },
    onInformPayment(item) {
      this.showOverlay = true
      store
        .dispatch('store-payment/getDefaultPayment', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.paymentData = response.data
          if (item.type === 'Booking') {
            this.paymentData.event.value = (item.id !== undefined) ? item.id : ''
            this.paymentData.event.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.event_id = (item.id !== undefined) ? item.id : ''
            this.paymentData.event_code = (item.booking_code !== undefined) ? item.booking_code : ''
            this.paymentData.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.product_type = (item.product.product_type_name !== undefined) ? item.product.product_type_name : ''
            this.paymentData.product_id = (item.product.id !== undefined) ? item.product.id : 0
            this.paymentData.type_code = (item.type_code !== undefined) ? item.type_code : ''
            this.paymentData.category_name = (item.product.category_name !== undefined) ? item.product.category_name : ''
            this.paymentData.department_name = (item.product.department_name !== undefined) ? item.product.department_name : ''
            this.paymentData.payment_amount = (item.fee_amount !== undefined) ? item.fee_amount : ''
            this.paymentData.event_type = 'booking'
          } else if (item.type === 'Renting') {
            this.paymentData.event.value = (item.id !== undefined) ? item.id : ''
            this.paymentData.event.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.event_id = (item.id !== undefined) ? item.id : ''
            this.paymentData.event_code = (item.renting_code !== undefined) ? item.renting_code : ''
            this.paymentData.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.product_type = (item.product.product_type_name !== undefined) ? item.product.product_type_name : ''
            this.paymentData.product_id = (item.product.id !== undefined) ? item.product.id : 0
            this.paymentData.type_code = (item.type_code !== undefined) ? item.type_code : ''
            this.paymentData.category_name = (item.product.category_name !== undefined) ? item.product.category_name : ''
            this.paymentData.department_name = (item.product.department_name !== undefined) ? item.product.department_name : ''
            this.paymentData.payment_amount = (item.amount !== undefined) ? item.amount : ''
            this.paymentData.payment_period = (item.payment_period !== undefined) ? item.payment_period : ''
            this.paymentData.payment_term = (item.payment_term !== undefined) ? item.payment_term : ''
            this.paymentData.event_type = 'renting'
          }

          this.paymentFile = {
            image: response.data.image,
          }
          this.typePaymentForm = 'Inform'
          this.shallShowPaymentFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onProcessPayment(item) {
      this.showOverlay = true
      store
        .dispatch('store-payment/getDefaultPayment', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.paymentData = response.data
          if (item.type === 'Booking') {
            this.paymentData.event.value = (item.id !== undefined) ? item.id : ''
            this.paymentData.event.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.event_id = (item.id !== undefined) ? item.id : ''
            this.paymentData.event_code = (item.booking_code !== undefined) ? item.booking_code : ''
            this.paymentData.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.product_type = (item.product.product_type_name !== undefined) ? item.product.product_type_name : ''
            this.paymentData.product_id = (item.product.id !== undefined) ? item.product.id : 0
            this.paymentData.type_code = (item.type_code !== undefined) ? item.type_code : ''
            this.paymentData.category_name = (item.product.category_name !== undefined) ? item.product.category_name : ''
            this.paymentData.department_name = (item.product.department_name !== undefined) ? item.product.department_name : ''
            this.paymentData.payment_amount = (item.fee_amount !== undefined) ? item.fee_amount : ''
            this.paymentData.amount = (item.fee_amount !== undefined) ? item.fee_amount : ''
            this.paymentData.event_type = 'booking'
          } else if (item.type === 'Renting') {
            this.paymentData.event.value = (item.id !== undefined) ? item.id : ''
            this.paymentData.event.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.event_id = (item.id !== undefined) ? item.id : ''
            this.paymentData.event_code = (item.renting_code !== undefined) ? item.renting_code : ''
            this.paymentData.name = (item.product.name !== undefined) ? item.product.name : ''
            this.paymentData.product_type = (item.product.product_type_name !== undefined) ? item.product.product_type_name : ''
            this.paymentData.product_id = (item.product.id !== undefined) ? item.product.id : 0
            this.paymentData.type_code = (item.type_code !== undefined) ? item.type_code : ''
            this.paymentData.category_name = (item.product.category_name !== undefined) ? item.product.category_name : ''
            this.paymentData.department_name = (item.product.department_name !== undefined) ? item.product.department_name : ''
            this.paymentData.payment_amount = (item.amount !== undefined) ? item.amount : ''
            this.paymentData.payment_period = (item.payment_period !== undefined) ? item.payment_period : ''
            this.paymentData.payment_term = (item.payment_term !== undefined) ? item.payment_term : ''
            this.paymentData.amount = (item.amount !== undefined) ? item.amount : ''
            this.paymentData.event_type = 'renting'
          }

          this.paymentFile = {
            image: response.data.image,
          }
          this.typePaymentForm = 'Process'
          this.shallShowPaymentFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onLineUserProfile(item) {
      console.log('on line user profile')
      console.log(item)
    },
    updatePaymentForm() {
      this.$emit('update-event-form')
      this.$emit('update:is-event-handler-sidebar-active', false)
    },
    discardPaymentForm() {
    },
  },
  setup() {
    const iconColor = color => $themeColors[color]

    const { scrolledTo } = useScrollListener()

    return {
      iconColor,
      scrolledTo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.text-extra{
  line-height: 1.5;
  font-size: 20px;
}
.text-header{
  line-height: 1.5;
  font-size: 18px;
}
.text-large{
  line-height: 1.5;
  font-size: 16px;
}
.text-normal{
  line-height: 1.5;
  font-size: 14px;
}
.text-small{
  line-height: 2;
  font-size: 12px;
}
.text-smaller{
  line-height: 2;
  font-size: 11px;
}
.van-cell{
  font-size: 16px !important;
}
.van-cell__title{
  font-size: 14px !important;
  color: #888888 !important;
}
.van-panel__header-value{
  color: blue !important;
}
.navbar-fixed-top {
  left: 0;
  width: 100%;
  position:fixed !important;
}
.form-fixed-top {
  left: 0;
  top: 0;
  width: 100%;
  position:fixed !important;
}
.text-with-dots {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-label-info {
  color: #323233;
}
.text-vertical-align-baseline {
  vertical-align: baseline;
}
.text-infomation {
  color: #000000;
}
.text-gray {
  color: #888888;
}
.text-light-gray {
  color: #aaaaaa;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.v-align-middle {
  vertical-align: middle !important;
}
.van-divider--content-left::before {
  max-width: 5% !important;
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }

  .van-nav-bar{
    background-color: $theme-dark-card-bg !important;

    .van-nav-bar__title{
      color: $theme-dark-label-color !important;
    }
  }
  .van-cell-group{
    background-color: $theme-dark-input-bg !important;
  }
  .van-cell-group .van-search{
    background-color: $theme-dark-input-bg !important;
    .van-search__content{
      background-color: $theme-dark-border-color !important;
    }
  }
  .van-cell__value--alone .van-field__control{
    color: $theme-dark-label-color !important;
  }
  .van-pull-refresh{
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-tabs__nav{
    background-color: $theme-dark-switch-bg !important;
  }
  .van-tab.van-tab--active{
    background-color: $theme-dark-switch-bg-disabled !important;
  }
  .van-tab__text{
    color: $theme-dark-label-color !important;
  }
  .van-divider{
    border-color: $theme-dark-label-color !important;
    color: $theme-dark-label-color !important;
  }
  .van-popup .van-action-sheet__header{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup > button{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__content .van-action-sheet__item{
    color: $theme-dark-label-color;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__gap{
    background-color: $theme-dark-border-color !important;
  }
  .van-popup{
    background-color: $theme-dark-body-bg !important;
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-cell{
    color: $theme-dark-label-color !important;
  }
  .van-picker{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
    .van-picker__mask{
      background-image: linear-gradient(180deg,hsla(0, 0%, 10%, 0.9),hsla(0,0%,10%,.4)),linear-gradient(0deg,hsla(0,0%,10%,.9),hsla(0,0%,10%,.4));
    }
    .van-picker-column__item{
      color: $theme-dark-label-color !important;
    }
    .van-picker__confirm{
      color: $theme-dark-label-color !important;
    }
    .van-picker__title{
      color: $theme-dark-label-color !important;
    }
    .van-picker__cancel{
      color: $theme-dark-label-color !important;
    }
  }
  .van-uploader__upload{
    background-color: $theme-dark-input-placeholder-color !important;
  }
  .van-calendar{
    background-color: $theme-dark-table-row-bg !important;
  }
  .text-infomation {
    color: $theme-dark-label-color !important;
  }
  .text-label-info {
    color: $theme-dark-label-color !important;
  }
  .badge.badge-light-secondary{
    color: $theme-dark-label-color !important;
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.nav-top-margin{
  margin-top: 60px !important;
}
.van-nav-bar__content {
  vertical-align: middle;
  height: 60px !important;
}
</style>
