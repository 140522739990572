import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import thLocale from '@fullcalendar/core/locales/th'
import enLocale from '@fullcalendar/core/locales/en-gb'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
// import { t } from '@/@core/libs/i18n/utils'

export default function userCalendar() {
  const toast = useToast()
  const refCalendar = ref(null)
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  const blankEvent = {
    title: '',
    start: '',
    allDay: true,
    extendedProps: {
      // project_code: '',
      task_date: '',
      unit_id: '',
      unit_no: '',
      qc_time: '',
      status: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        // project_code,
        task_date,
        unit_id,
        unit_no,
        qc_time,
        status,
      },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      extendedProps: {
        // project_code,
        task_date,
        unit_id,
        unit_no,
        qc_time,
        status,
      },
      allDay,
    }
  }

  const addEvent = eventData => {
    const postData = {
      // project_code: eventData.extendedProps.project_code,
      task_date: eventData.start,
      unit_id: eventData.extendedProps.unit_id,
      unit_no: eventData.extendedProps.unit_no,
      create_by: eventData.create_by,
      qc_time: eventData.extendedProps.qc_time,
    }
    store.dispatch('calendar/addEvent', postData).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  const updateEvent = eventData => {
    // console.log('update event')
    // console.log(eventData)
    eventData.extendedProps.task_date = eventData.start
    eventData.extendedProps.update_by = eventData.create_by
    eventData.extendedProps.id = eventData.id
    store.dispatch('calendar/updateEvent', eventData.extendedProps).then(response => {
      const updatedEvent = response.data

      const propsToUpdate = ['id', 'title']
      const extendedPropsToUpdate = [
        // 'project_code',
        'task_date',
        'unit_no',
        'qc_time',
        'status',
      ]

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const selectedStatus = computed(() => store.state.calendar.selectedStatus)
  const selectedType = computed(() => store.state.calendar.selectedType)

  watch([selectedStatus, selectedType], () => {
    refetchEvents()
  })

  const locale = ref('th')

  watch(locale, () => {
    if (locale.value === 'en') {
      calendarApi.setOption('locale', 'en')
    } else {
      calendarApi.setOption('locale', 'th')
    }
  })

  const lineOa = computed(() => store.state.lineapi.lineOa)

  // const projectCode = computed(() => store.state.project.projectCode)

  // watch(projectCode, () => {
  //   refetchEvents()
  // })

  const fetchEvents = (info, successCallback) => {
    if (!info) return

    const { id } = JSON.parse(lineOa.value)

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        language: locale.value,
        lineOaId: id,
        info,
        // projectCode: projectCode.value,
        status: selectedStatus.value,
        type: selectedType.value,
      })
      .then(response => {
        successCallback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Process on error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth,',
    },
    timeZone: 'local',
    // timeZone: 'Asia/Bangkok',
    events: fetchEvents,
    editable: false,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 2,
    navLinks: true,
    locales: [enLocale, thLocale],
    locale: 'th',
    firstDay: 0,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    },

    eventClassNames({ event: statusEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const extProps = statusEvent._def.extendedProps
      const colorName = extProps.status_color
      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      // event.value = grabEventDataFromEventApi(clickedEvent)
      // eslint-disable-next-line no-underscore-dangle
      event.value = clickedEvent._def.extendedProps

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    // eslint-disable-next-line no-unused-vars
    dateClick(info) {
      // event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // // eslint-disable-next-line no-use-before-define
      // isEventHandlerSidebarActive.value = true
    },

    // eslint-disable-next-line no-unused-vars
    eventDrop({ event: droppedEvent }) {
      // updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    rerenderDelay: 350,
  })

  const isEventHandlerSidebarActive = ref(false)
  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    locale,

    isEventHandlerSidebarActive,
  }
}
