<template>
  <div
    v-if="detailData"
  >
    <van-popup
      v-model="showDetail"
      position="bottom"
      :lock-scroll="true"
      :style="{ height: '100%' }"
      @opened="openedDetail"
    >
      <div
        ref="refDetailTop"
        style="scroll-margin-top: 60px;"
      />
      <b-overlay
        :show="showOverlayDetail"
        rounded="sm"
      >
        <van-nav-bar
          class="p-0 m-0 form-fixed-top nav-height"
          :title="detailData.title"
        >
          <template #left>
            <b-button
              variant="outline-primary"
              @click.stop="onCloseDetail"
            >
              <feather-icon
                icon="CornerLeftDownIcon"
                class="v-align-middle"
              />
              {{ $t('Close') }}
            </b-button>
          </template>
          <template
            v-if="detailData.mode === 'select'"
            #right
          >
            <b-button
              variant="primary"
              @click="onActionSelect"
            >
              <feather-icon
                icon="CheckIcon"
                class="v-align-middle"
              />
              {{ $t('Select') }}
            </b-button>
          </template>
        </van-nav-bar>

        <div
          style="height: 3px; width: 100%; align-items: center; text-align: center; z-index: 999; position: fixed; top: 0;"
          :style="`background-color: ${iconColor('success')} !important;`"
        />

        <div
          style="margin-top: 60px;"
        >
          <van-swipe>
            <van-swipe-item
              v-for="(image, index) in detailData.product_image_preview"
              :key="index"
            >
              <van-image
                width="100%"
                fit="fill"
                radius="0"
                :lazy-load="true"
                :src="image"
                @click.stop="onImagePreview(detailData)"
              />
            </van-swipe-item>
          </van-swipe>
          <b-row class="p-0 ml-25 mr-0 mt-50 mb-0">
            <b-col
              cols="8"
            >
              <div class="text-large d-block text-wrap mt-50">
                <feather-icon
                  :color="iconColor('success')"
                  icon="DiscIcon"
                  size="16"
                  class="v-align-middle"
                />
                {{ detailData.category_name }}
              </div>
            </b-col>
            <b-col
              cols="4"
              class="pl-0 text-right h-100 pr-50"
            >
              <b-badge
                pill
                variant="light-success"
                class="w-100 py-25 text-wrap"
              >
                <span class="text-small">
                  {{ ($i18n.locale === 'th')? detailData.product_type_name : detailData.product_type_name_en }}
                </span>
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="p-0 ml-25 mr-0 mt-25 mb-0">
            <b-col
              cols="12"
            >
              <div class="text-normal d-block text-wrap text-gray">
                {{ $t('Department') }}
                <feather-icon
                  icon="ChevronsRightIcon"
                  size="16"
                  class="v-align-middle"
                />
                {{ detailData.department_name }}
              </div>
            </b-col>
          </b-row>
          <van-divider class="m-75" />
          <b-row class="p-0 ml-25 mr-0 my-50">
            <b-col
              cols="12"
            >
              <div class="text-header d-block text-wrap">
                <feather-icon
                  :color="iconColor('success')"
                  icon="BookmarkIcon"
                  size="20"
                  class="v-align-middle"
                />
                {{ detailData.name }}
              </div>
            </b-col>
          </b-row>
          <b-card
            no-body
            class="mx-50 my-75 h-100"
          >
            <van-divider
              class="m-0 p-0 py-50"
              content-position="left"
            >
              {{ $t('Description') }}
            </van-divider>
            <b-row class="p-0 ml-25 mr-0 pb-1">
              <b-col>
                <div class="text-large d-block text-wrap">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="detailData.description" />
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card
            no-body
            class="mx-50 my-75 h-100"
          >
            <van-divider
              class="m-0 p-0 py-50"
              content-position="left"
            >
              {{ $t('Specification') }}
            </van-divider>
            <b-row
              v-for="(spec, specIndex) in detailData.product_spec"
              :key="specIndex"
              class="p-0 ml-25 mr-0 my-50"
            >
              <b-col
                cols="8"
                class="p-0 pl-1"
              >
                <div class="text-normal d-block text-wrap">
                  <feather-icon
                    :color="iconColor('success')"
                    icon="ToggleLeftIcon"
                    size="16"
                    class="v-align-middle"
                  />
                  {{ spec.name }}
                </div>
              </b-col>
              <b-col
                cols="4"
                class="p-0 pl-1 pr-50"
              >
                <div class="text-normal d-block text-wrap text-right mr-1">
                  {{ specToMessage(spec) }}
                </div>
              </b-col>
            </b-row>
            <div style="height: 0.75rem;" />
          </b-card>
          <b-card
            no-body
            class="mx-50 my-75 h-100"
          >
            <van-divider
              class="m-0 p-0 py-1"
              content-position="left"
            >
              {{ $t('Service Fee') }}
            </van-divider>
            <b-row
              v-for="(fee, feeIndex) in detailData.product_fee"
              :key="feeIndex"
              class="p-0 ml-25 mr-0 my-50"
            >
              <b-col
                cols="4"
                class="p-0 pl-1"
              >
                <div class="text-normal d-block text-wrap">
                  <feather-icon
                    :color="iconColor('warning')"
                    icon="TagIcon"
                    size="16"
                    class="v-align-middle"
                  />
                  {{ feeTypeToMessage(fee) }}
                </div>
              </b-col>
              <b-col
                cols="3"
                class="p-0 pl-1"
              >
                <div class="text-normal d-block text-wrap">
                  {{ `${fee.fee_qty} ${$t(`PageBooking.Unit.${fee.fee_qty_unit}`)}` }}
                </div>
              </b-col>
              <b-col
                cols="5"
                class="p-0 pl-1 pr-50"
              >
                <div class="text-normal d-block text-wrap text-right mr-75">
                  {{ `${fee.fee_value_show} ${$t(`${fee.fee_value_unit}`)}` }}
                </div>
              </b-col>
            </b-row>
            <div style="height: 0.75rem;" />
          </b-card>
        </div>
      </b-overlay>
    </van-popup>
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BBadge,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'
import { $themeColors } from '@themeConfig'
import {
  ImagePreview,
} from 'vant'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BOverlay,
    BButton,
    BBadge,
    BRow,
    BCol,
    BCard,
    FeatherIcon,
  },
  model: {
    prop: 'showDetail',
    event: 'update:show-detail',
  },
  props: {
    showDetail: {
      type: Boolean,
      required: true,
    },
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showOverlayDetail: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('update:show-detail', false)
    },
    onCloseDetail() {
      this.onClose()
    },
    onActionSelect() {
      this.$emit('on-action-select', this.detailData)
    },
    scrollToTopDetail() {
      this.$refs.refDetailTop.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    openedDetail() {
      this.scrollToTopDetail()
    },
    onImagePreview(item) {
      if (item.product_image_preview.length > 0) {
        ImagePreview({
          images: item.product_image_preview,
          closeable: true,
        })
      }
    },
    specToMessage(spec) {
      let msg = ''
      // eslint-disable-next-line default-case
      switch (spec.type) {
        case 'boolean':
          msg = this.$i18n.t('Available')
          break
        case 'number':
          msg = spec.value
          if (spec.unit !== '' && spec.unit !== undefined) {
            msg += ` ${spec.unit}`
          }
          break
      }
      return msg
    },
    feeTypeToMessage(fee) {
      let msg = ''
      if (this.$i18n.locale === 'th') {
        msg = `${fee.fee_type_name}`
      } else {
        msg = `${fee.fee_type_name_en}`
      }
      return msg
    },
  },
  setup() {
    const iconColor = color => $themeColors[color]

    const { scrolledTo } = useScrollListener()

    return {
      iconColor,
      scrolledTo,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-extra{
  line-height: 1.5;
  font-size: 20px;
}
.text-header{
  line-height: 1.5;
  font-size: 18px;
}
.text-large{
  line-height: 1.5;
  font-size: 16px;
}
.text-normal{
  line-height: 1.5;
  font-size: 14px;
}
.text-small{
  line-height: 2;
  font-size: 12px;
}
.text-smaller{
  line-height: 2;
  font-size: 11px;
}
.van-cell{
  font-size: 16px !important;
}
.van-cell__title{
  font-size: 14px !important;
  color: #888888 !important;
}
.van-panel__header-value{
  color: blue !important;
}
.navbar-fixed-top {
  left: 0;
  width: 100%;
  position:fixed !important;
}
.form-fixed-top {
  left: 0;
  top: 0;
  width: 100%;
  position:fixed !important;
}
.text-with-dots {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-label-info {
  color: #323233;
}
.text-vertical-align-baseline {
  vertical-align: baseline;
}
.text-infomation {
  color: #000000;
}
.text-gray {
  color: #888888;
}
.text-light-gray {
  color: #aaaaaa;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.v-align-middle {
  vertical-align: middle !important;
}
.van-divider--content-left::before {
  max-width: 5% !important;
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }

  .van-nav-bar{
    background-color: $theme-dark-card-bg !important;

    .van-nav-bar__title{
      color: $theme-dark-label-color !important;
    }
  }
  .van-cell-group{
    background-color: $theme-dark-input-bg !important;
  }
  .van-cell-group .van-search{
    background-color: $theme-dark-input-bg !important;
    .van-search__content{
      background-color: $theme-dark-border-color !important;
    }
  }
  .van-cell__value--alone .van-field__control{
    color: $theme-dark-label-color !important;
  }
  .van-pull-refresh{
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-tabs__nav{
    background-color: $theme-dark-switch-bg !important;
  }
  .van-tab.van-tab--active{
    background-color: $theme-dark-switch-bg-disabled !important;
  }
  .van-tab__text{
    color: $theme-dark-label-color !important;
  }
  .van-divider{
    border-color: $theme-dark-label-color !important;
    color: $theme-dark-label-color !important;
  }
  .van-popup .van-action-sheet__header{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup > button{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__content .van-action-sheet__item{
    color: $theme-dark-label-color;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__gap{
    background-color: $theme-dark-border-color !important;
  }
  .van-popup{
    background-color: $theme-dark-body-bg !important;
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-cell{
    color: $theme-dark-label-color !important;
  }
  .van-picker{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
    .van-picker__mask{
      background-image: linear-gradient(180deg,hsla(0, 0%, 10%, 0.9),hsla(0,0%,10%,.4)),linear-gradient(0deg,hsla(0,0%,10%,.9),hsla(0,0%,10%,.4));
    }
    .van-picker-column__item{
      color: $theme-dark-label-color !important;
    }
    .van-picker__confirm{
      color: $theme-dark-label-color !important;
    }
    .van-picker__title{
      color: $theme-dark-label-color !important;
    }
    .van-picker__cancel{
      color: $theme-dark-label-color !important;
    }
  }
  .van-uploader__upload{
    background-color: $theme-dark-input-placeholder-color !important;
  }
  .van-calendar{
    background-color: $theme-dark-table-row-bg !important;
  }
  .text-infomation {
    color: $theme-dark-label-color !important;
  }
  .text-label-info {
    color: $theme-dark-label-color !important;
  }
  .badge.badge-light-secondary{
    color: $theme-dark-label-color !important;
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.nav-top-margin{
  margin-top: 60px !important;
}
.van-nav-bar__content {
  vertical-align: middle;
  height: 60px !important;
}
</style>
