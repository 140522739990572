import store from '@/store'

import { computed } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default function useCalendarSidebar() {
  const typeOptions = computed(() => store.state.calendar.typeOptions)
  const statusOptions = computed(() => store.state.calendar.statusOptions)
  const selectedStatus = computed({
    get: () => store.state.calendar.selectedStatus,
    set: val => {
      store.commit('calendar/SET_SELECTED_STATUS', val)
      localStorage.setItem(`${$themeConfig.app.commonSession}-calendar-event-status`, JSON.stringify(val))
    },
  })
  const selectedType = computed({
    get: () => store.state.calendar.selectedType,
    set: val => {
      store.commit('calendar/SET_SELECTED_TYPE', val)
      localStorage.setItem(`${$themeConfig.app.commonSession}-calendar-event-type`, JSON.stringify(val))
    },
  })

  const checkAllStatus = computed({
    get: () => selectedStatus.value.length === statusOptions.value.length,
    set: val => {
      if (val) {
        selectedStatus.value = statusOptions.value.map(i => i.label)
      } else if (selectedStatus.value.length === statusOptions.value.length) {
        selectedStatus.value = []
      }
    },
  })

  const checkAllType = computed({
    get: () => selectedType.value.length === typeOptions.value.length,
    set: val => {
      if (val) {
        selectedType.value = typeOptions.value.map(i => i.label)
      } else if (selectedType.value.length === typeOptions.value.length) {
        selectedType.value = []
      }
    },
  })

  return {
    statusOptions,
    selectedStatus,
    checkAllStatus,
    typeOptions,
    selectedType,
    checkAllType,
  }
}
