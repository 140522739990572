<template>
  <div v-if="headerData.product">
    <!-- Product -->
    <van-cell-group>
      <van-grid
        :border="true"
        :center="false"
        :column-num="1"
      >
        <div
          class="w-100 px-50 py-50 m-0 mt-25"
        >
          <div
            class="p-0 px-50"
          >
            <b-media vertical-align="left">
              <template #aside>
                <div class="d-block">
                  <van-image
                    width="60"
                    height="60"
                    fit="fill"
                    radius="10"
                    :lazy-load="true"
                    :src="headerData.product.product_image_thumbnail"
                    class="cursor-pointer"
                    @click.stop="onImagePreview(headerData.product)"
                  />
                </div>
              </template>
              <b-row
                class="cursor-pointer"
                @click.prevent="onProductDetail(headerData.product)"
              >
                <b-col
                  cols="8"
                  class="pl-50"
                >
                  <div class="text-normal d-block text-wrap text-gray mt-25">
                    <feather-icon
                      icon="DiscIcon"
                      size="14"
                      class="v-align-middle"
                      :color="iconColor('success')"
                    />
                    {{ (headerData.product.category_name !== undefined)? headerData.product.category_name : '' }}
                  </div>
                  <div class="text-header d-block text-wrap text-black pt-25">
                    {{ headerData.product.name }}
                  </div>
                </b-col>
                <b-col
                  cols="4"
                  class="pl-0 text-right h-100"
                >
                  <b-row>
                    <b-col>
                      <b-badge
                        pill
                        variant="light-success"
                        class="w-100 px-25 py-0 text-wrap"
                      >
                        <span class="text-smaller">
                          {{ ($i18n.locale === 'th')? headerData.product.product_type_name : headerData.product.product_type_name_en }}
                        </span>
                      </b-badge>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        class="mt-75 mr-25"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-media>
          </div>
        </div>
      </van-grid>
    </van-cell-group>
    <!-- Calendar/Date :: Booking -->
    <van-cell-group v-if="headerData.type === 'Booking'">
      <b-row>
        <b-col
          cols="12"
          class="ml-75 pl-75 pr-75 m-0"
        >
          <div class="p-0 m-0">
            <b-row class="my-50 mx-75">
              <b-col
                cols="8"
                class="pl-50 mt-25"
              >
                <span class="text-small">
                  {{ $t('PageBooking.Title.Booking Date') }}
                </span>
              </b-col>
              <b-col
                cols="4"
                class="pl-1 text-right h-100"
              >
                <b-badge
                  pill
                  variant="light-success"
                  class="w-100 py-0 text-wrap"
                >
                  <span class="text-small">{{ $t(`PageBooking.FeeTypeTitle.${headerData.fee_type_code}`) }}</span>
                </b-badge>
              </b-col>
            </b-row>
          </div>
          <div class="mx-50 mt-0 mb-25 d-flex">
            <div>
              <b-alert
                show
                variant="secondary"
                class="px-1 py-50 m-0 mb-25"
              >
                <div
                  class="text-base d-block text-wrap align-middle"
                >
                  <feather-icon
                    class="mr-0"
                    icon="CalendarIcon"
                  />
                  {{ `${bookingStartEndDate().start}` }}
                </div>
              </b-alert>
            </div>
            <div
              v-if="bookingStartEndDate().end !== ''"
              class="px-1 mt-50"
            >
              {{ $t("To") }}
            </div>
            <div v-if="bookingStartEndDate().end !== ''">
              <b-alert
                show
                variant="secondary"
                class="px-1 py-50 m-0 mb-25"
              >
                <div
                  class="text-base d-block text-wrap align-middle"
                >
                  <feather-icon
                    class="mr-0"
                    icon="CalendarIcon"
                  />
                  {{ `${bookingStartEndDate().end}` }}
                </div>
              </b-alert>
            </div>
          </div>
        </b-col>
      </b-row>
    </van-cell-group>
    <!-- Calendar/Date :: Renting -->
    <van-cell-group v-else-if="headerData.type === 'Renting'">
      <b-row>
        <b-col
          cols="12"
          class="ml-75 pl-75 pr-75 m-0"
        >
          <div class="p-0 m-0">
            <b-row class="my-50 mx-75">
              <b-col
                cols="8"
                class="pl-50 mt-25"
              >
                <span class="text-small">
                  {{ $t('PageRenting.Title.Renting Date') }}
                </span>
              </b-col>
              <b-col
                cols="4"
                class="pl-1 text-right h-100"
              >
                <b-badge
                  pill
                  variant="light-success"
                  class="w-100 py-0 text-wrap"
                >
                  <span class="text-small">{{ $t(`PageRenting.FeeTypeTitle.${headerData.fee_type_code}`) }}</span>
                </b-badge>
              </b-col>
            </b-row>
          </div>
          <div class="mx-50 mt-0 mb-25 d-flex">
            <div>
              <b-alert
                show
                variant="secondary"
                class="px-1 py-50 m-0 mb-25"
              >
                <div
                  class="text-base d-block text-wrap align-middle"
                >
                  <feather-icon
                    class="mr-0"
                    icon="CalendarIcon"
                  />
                  {{ `${rentingStartEndDate().start}` }}
                </div>
              </b-alert>
            </div>
            <div
              v-if="rentingStartEndDate().end !== ''"
              class="px-1 mt-50"
            >
              {{ $t("To") }}
            </div>
            <div v-if="rentingStartEndDate().end !== ''">
              <b-alert
                show
                variant="secondary"
                class="px-1 py-50 m-0 mb-25"
              >
                <div
                  class="text-base d-block text-wrap align-middle"
                >
                  <feather-icon
                    class="mr-0"
                    icon="CalendarIcon"
                  />
                  {{ `${rentingStartEndDate().end}` }}
                </div>
              </b-alert>
            </div>
          </div>
        </b-col>
      </b-row>
    </van-cell-group>

    <!-- Booking Product Detail -->
    <ProductDetail
      v-model="showProductDetail"
      :detail-data="productDetailData"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BAlert,
  BMedia,
} from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'
import { $themeColors } from '@themeConfig'
import {
  Locale,
  ImagePreview,
} from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import thTH from 'vant/es/locale/lang/th-TH'
// import moment from 'moment'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ProductDetail from './ProductDetail.vue'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BAlert,
    BMedia,
    FeatherIcon,
    ProductDetail,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // for product detail
      showProductDetail: false,
      productDetailData: null,

      locale: 'th',
    }
  },
  mounted() {
    this.locale = this.$i18n.locale

    if (this.locale === 'en') {
      Locale.use('en-US', enUS)
    } else if (this.locale === 'th') {
      Locale.use('th-TH', thTH)
    }
  },
  methods: {
    onImagePreview(item) {
      if (item.product_image_preview.length > 0) {
        ImagePreview({
          images: item.product_image_preview,
          closeable: true,
        })
      }
    },
    onProductDetail(item) {
      this.productDetailData = item
      this.productDetailData.mode = 'view'
      this.showProductDetail = true
    },
    bookingStartEndDate() {
      // const date = {
      //   start: '',
      //   end: '',
      // }
      // const day = this.headerData.booking_date

      // if (Array.isArray(day) === true) {
      //   const from = (day[0] !== null) ? moment(day[0]).toDate() : null
      //   if (from !== null) {
      //     date.start = moment(from).format('DD MMMM YYYY')
      //   }
      //   const to = (day[1] !== null) ? moment(day[1]).toDate() : null
      //   if (to !== null) {
      //     date.end = moment(to).format('DD MMMM YYYY')
      //   }
      // } else {
      //   const from = (day !== null) ? moment(day).toDate() : null
      //   if (from !== null) {
      //     date.start = moment(from).format('DD MMMM YYYY')
      //   }
      //   date.end = ''
      // }
      let date = {
        start: this.headerData.start_date.name,
        end: '',
      }
      if (this.headerData.start_date.value !== this.headerData.end_date.value) {
        // between
        date = {
          start: this.headerData.start_date.name,
          end: this.headerData.end_date.name,
        }
      }
      return date
    },
    rentingStartEndDate() {
      const date = {
        start: this.headerData.start_date.name,
        end: this.headerData.end_date.name,
      }
      return date
    },
  },
  setup() {
    const iconColor = color => $themeColors[color]

    const { scrolledTo } = useScrollListener()

    return {
      iconColor,
      scrolledTo,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-extra{
  line-height: 1.5;
  font-size: 20px;
}
.text-header{
  line-height: 1.5;
  font-size: 18px;
}
.text-large{
  line-height: 1.5;
  font-size: 16px;
}
.text-normal{
  line-height: 1.5;
  font-size: 14px;
}
.text-small{
  line-height: 2;
  font-size: 12px;
}
.text-smaller{
  line-height: 2;
  font-size: 11px;
}
.van-cell{
  font-size: 16px !important;
}
.van-cell__title{
  font-size: 14px !important;
  color: #888888 !important;
}
.van-panel__header-value{
  color: blue !important;
}
.navbar-fixed-top-view {
  left: 0;
  width: 100%;
  position:fixed !important;
}
.form-fixed-top {
  left: 0;
  top: 0;
  width: 100%;
  position:fixed !important;
}
.text-with-dots {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-label-info {
  color: #323233;
}
.text-vertical-align-baseline {
  vertical-align: baseline;
}
.text-infomation {
  color: #000000;
}
.text-gray {
  color: #888888;
}
.text-light-gray {
  color: #aaaaaa;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.v-align-middle {
  vertical-align: middle !important;
}
.van-divider--content-left::before {
  max-width: 5% !important;
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }

  .van-nav-bar{
    background-color: $theme-dark-card-bg !important;

    .van-nav-bar__title{
      color: $theme-dark-label-color !important;
    }
  }
  .van-cell-group{
    background-color: $theme-dark-input-bg !important;
  }
  .van-cell-group .van-search{
    background-color: $theme-dark-input-bg !important;
    .van-search__content{
      background-color: $theme-dark-border-color !important;
    }
  }
  .van-cell__value--alone .van-field__control{
    color: $theme-dark-label-color !important;
  }
  .van-pull-refresh{
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-tabs__nav{
    background-color: $theme-dark-switch-bg !important;
  }
  .van-tab.van-tab--active{
    background-color: $theme-dark-switch-bg-disabled !important;
  }
  .van-tab__text{
    color: $theme-dark-label-color !important;
  }
  .van-divider{
    border-color: $theme-dark-label-color !important;
    color: $theme-dark-label-color !important;
  }
  .van-popup .van-action-sheet__header{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup > button{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__content .van-action-sheet__item{
    color: $theme-dark-label-color;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__gap{
    background-color: $theme-dark-border-color !important;
  }
  .van-popup{
    background-color: $theme-dark-body-bg !important;
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-cell{
    color: $theme-dark-label-color !important;
  }
  .van-picker{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
    .van-picker__mask{
      background-image: linear-gradient(180deg,hsla(0, 0%, 10%, 0.9),hsla(0,0%,10%,.4)),linear-gradient(0deg,hsla(0,0%,10%,.9),hsla(0,0%,10%,.4));
    }
    .van-picker-column__item{
      color: $theme-dark-label-color !important;
    }
    .van-picker__confirm{
      color: $theme-dark-label-color !important;
    }
    .van-picker__title{
      color: $theme-dark-label-color !important;
    }
    .van-picker__cancel{
      color: $theme-dark-label-color !important;
    }
  }
  .van-uploader__upload{
    background-color: $theme-dark-input-placeholder-color !important;
  }
  .van-calendar{
    background-color: $theme-dark-table-row-bg !important;
  }
  .text-infomation {
    color: $theme-dark-label-color !important;
  }
  .text-label-info {
    color: $theme-dark-label-color !important;
  }
  .badge.badge-light-secondary{
    color: $theme-dark-label-color !important;
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.nav-top-margin{
  margin-top: 60px !important;
}
.van-nav-bar__content {
  vertical-align: middle;
  height: 60px !important;
}
</style>
