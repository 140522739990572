import axios from '@axios'

export default {
  namespaced: true,
  state: {
    statusOptions: [
      // {
      //   color: 'info',
      //   label: 'created',
      // },
      // {
      //   color: 'info',
      //   label: 'wait_submit',
      // },
      {
        color: 'warning',
        label: 'wait_approve',
      },
      {
        color: 'success',
        label: 'approved',
      },
      {
        color: 'danger',
        label: 'disapproved',
      },
      {
        color: 'danger',
        label: 'canceled',
      },
      {
        color: 'info',
        label: 'wait_payment',
      },
      {
        color: 'info',
        label: 'wait_usage',
      },
      {
        color: 'warning',
        label: 'usage_start',
      },
      {
        color: 'success',
        label: 'usage_end',
      },
    ],
    selectedStatus: [
      // 'created',
      // 'wait_submit',
      'wait_approve',
      'approved',
      'disapproved',
      'canceled',
      'wait_payment',
      'wait_usage',
      'usage_start',
      'usage_end',
    ],
    typeOptions: [
      {
        color: 'info',
        label: 'Booking',
        icon: 'CalendarIcon',
      },
      {
        color: 'success',
        label: 'Renting',
        icon: 'MapPinIcon',
      },
    ],
    selectedType: ['Booking', 'Renting'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_STATUS(state, val) {
      state.selectedStatus = val
    },
    SET_SELECTED_TYPE(state, val) {
      state.selectedType = val
    },
  },
  actions: {
    fetchEvents(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_calendar_event', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEvent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_calendar_event', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_calendar_event', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/update_calendar_event', event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_calendar_event', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
