<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-show-event-form.sync="isShowEventForm" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
              <template v-slot:eventContent="arg">
                <div class="text-normal text-wrap">
                  <span class="text-normal mr-0">
                    <feather-icon
                      :icon="(arg.event.extendedProps.type === 'Booking')? 'CalendarIcon' : 'MapPinIcon'"
                      size="12"
                      style="margin-top: -0.2rem;"
                    />
                    {{ arg.event.title }}
                  </span>
                  <span class="ml-50">
                    {{ `${arg.event.extendedProps.name}` }}
                  </span>
                  <span
                    v-if="arg.event.extendedProps.start_time !== undefined"
                    class="ml-50"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="10"
                      style="margin-top: -0.15rem; margin-right: 0.2rem;"
                    />{{ `${arg.event.extendedProps.start_time} - ${arg.event.extendedProps.end_time}` }}
                  </span>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />

      <calendar-event-form
        v-model="isShowEventForm"
        :event-data="eventData"
        :type-event-form="typeEventForm"
        @update-event-form="updateEventForm"
        @discard-event-form="discardEventForm"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { $themeColors } from '@themeConfig'
import storePayment from '@/views/booking/payment/storePayment'
import storeCalendar from './storeCalendar'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import CalendarEventForm from './calendar-event-form/CalendarEventForm.vue'

export default {
  components: {
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
    CalendarEventForm,
  },
  data() {
    return {
      isShowEventForm: false,
      eventData: {},
      typeEventForm: 'Add',
    }
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        this.locale = value
      },
    },
  },
  mounted() {
    this.locale = this.$i18n.locale
  },
  methods: {
    updateEventForm() {
      this.refetchEvents()
    },
    discardEventForm() {
    },
  },
  setup() {
    if (!store.hasModule('calendar')) store.registerModule('calendar', storeCalendar)
    if (!store.hasModule('store-payment')) store.registerModule('store-payment', storePayment)
    onUnmounted(() => {
      if (store.hasModule('calendar')) store.unregisterModule('calendar')
      if (store.hasModule('store-payment')) store.unregisterModule('store-payment')
    })

    const iconColor = color => $themeColors[color]

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      locale,

      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      iconColor,

      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      locale,

      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
