<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-if="$can('create', 'ModuleBooking.Calendar')"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isShowEventForm)"
        variant="primary"
        block
        @click="$emit('update:isShowEventForm', true)"
      >
        {{ $t('Add Event') }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Type') }}</span>
        </h5>
        <b-form-checkbox
          v-model="checkAllType"
          class="mb-1"
        >
          {{ $t('View All') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedType"
            name="type-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in typeOptions"
              :key="item.label"
              name="type-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              <feather-icon
                v-if="item.icon !== undefined"
                :icon="item.icon"
                size="12"
              />
              {{ $t(item.label) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Status') }}</span>
        </h5>
        <b-form-checkbox
          v-model="checkAllStatus"
          class="mb-1"
        >
          {{ $t('View All') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedStatus"
            name="status-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in statusOptions"
              :key="item.label"
              name="status-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ $t(`StatusMessage.${item.label}`) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    FeatherIcon,
  },
  props: {
    isShowEventForm: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      statusOptions,
      selectedStatus,
      checkAllStatus,
      typeOptions,
      selectedType,
      checkAllType,
    } = useCalendarSidebar()

    const calendarEventType = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-calendar-event-type`))
    if (calendarEventType !== null) {
      selectedType.value = calendarEventType
    } else {
      localStorage.setItem(`${$themeConfig.app.commonSession}-calendar-event-type`, JSON.stringify(selectedType.value))
    }

    const calendarEventStatus = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-calendar-event-status`))
    if (calendarEventStatus !== null) {
      selectedStatus.value = calendarEventStatus
    } else {
      localStorage.setItem(`${$themeConfig.app.commonSession}-calendar-event-status`, JSON.stringify(selectedStatus.value))
    }

    return {
      statusOptions,
      selectedStatus,
      checkAllStatus,
      typeOptions,
      selectedType,
      checkAllType,
    }
  },
}
</script>

<style>

</style>
