<template>
  <div v-if="bodyData.select !== undefined && bodyData.select !== null">
    <!-- Slot Time Booking List -->
    <div
      v-for="slot in bodyData.select.slot_time.slot"
      :key="slot.date"
    >
      <div
        v-if="slot.booking !== 0"
        class="mx-1 my-25 px-50 py-1"
        :style="slot.style.slot"
        style="border-radius: 10px; border: 1px solid #dddddd; background-color: #ffffff;"
      >
        <b-row>
          <b-col cols="4">
            <div
              class="d-flex ml-25"
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
                style="vertical-align: middle; margin-top: 0.42rem;"
              />
              <span
                class="ml-50 text-header"
                :style="slot.style.start"
              >
                {{ slot.start }}
              </span>
            </div>
          </b-col>
          <b-col cols="4">
            <span
              class="ml-1 text-header"
              style="color: #666666;"
            >
              {{ slot.style.text }}
            </span>
          </b-col>
          <b-col cols="4">
            <div
              class="d-flex text-right mr-25"
              style="float: right;"
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
                style="vertical-align: middle; margin-top: 0.42rem;"
              />
              <span
                class="ml-50 text-header"
                :style="slot.style.end"
              >
                {{ slot.end }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    bodyData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.text-extra{
  line-height: 1.5;
  font-size: 20px;
}
.text-header{
  line-height: 1.5;
  font-size: 18px;
}
.text-large{
  line-height: 1.5;
  font-size: 16px;
}
.text-normal{
  line-height: 1.5;
  font-size: 14px;
}
.text-small{
  line-height: 2;
  font-size: 12px;
}
.text-smaller{
  line-height: 2;
  font-size: 11px;
}
.van-cell{
  font-size: 16px !important;
}
.van-cell__title{
  font-size: 14px !important;
  color: #888888 !important;
}
.van-panel__header-value{
  color: blue !important;
}
.navbar-fixed-top-view {
  left: 0;
  width: 100%;
  position:fixed !important;
}
.form-fixed-top {
  left: 0;
  top: 0;
  width: 100%;
  position:fixed !important;
}
.text-with-dots {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-label-info {
  color: #323233;
}
.text-vertical-align-baseline {
  vertical-align: baseline;
}
.text-infomation {
  color: #000000;
}
.text-gray {
  color: #888888;
}
.text-light-gray {
  color: #aaaaaa;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.v-align-middle {
  vertical-align: middle !important;
}
.van-divider--content-left::before {
  max-width: 5% !important;
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }

  .van-nav-bar{
    background-color: $theme-dark-card-bg !important;

    .van-nav-bar__title{
      color: $theme-dark-label-color !important;
    }
  }
  .van-cell-group{
    background-color: $theme-dark-input-bg !important;
  }
  .van-cell-group .van-search{
    background-color: $theme-dark-input-bg !important;
    .van-search__content{
      background-color: $theme-dark-border-color !important;
    }
  }
  .van-cell__value--alone .van-field__control{
    color: $theme-dark-label-color !important;
  }
  .van-pull-refresh{
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-tabs__nav{
    background-color: $theme-dark-switch-bg !important;
  }
  .van-tab.van-tab--active{
    background-color: $theme-dark-switch-bg-disabled !important;
  }
  .van-tab__text{
    color: $theme-dark-label-color !important;
  }
  .van-divider{
    border-color: $theme-dark-label-color !important;
    color: $theme-dark-label-color !important;
  }
  .van-popup .van-action-sheet__header{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup > button{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__content .van-action-sheet__item{
    color: $theme-dark-label-color;
    background-color: $theme-dark-table-row-bg !important;
  }
  .van-popup .van-action-sheet__gap{
    background-color: $theme-dark-border-color !important;
  }
  .van-popup{
    background-color: $theme-dark-body-bg !important;
    .van-cell{
      .van-cell__value--alone{
        color: $theme-dark-label-color !important;
      }
      background-color: $theme-dark-table-row-bg !important;
    }
  }
  .van-cell{
    color: $theme-dark-label-color !important;
  }
  .van-picker{
    color: $theme-dark-label-color !important;
    background-color: $theme-dark-table-row-bg !important;
    .van-picker__mask{
      background-image: linear-gradient(180deg,hsla(0, 0%, 10%, 0.9),hsla(0,0%,10%,.4)),linear-gradient(0deg,hsla(0,0%,10%,.9),hsla(0,0%,10%,.4));
    }
    .van-picker-column__item{
      color: $theme-dark-label-color !important;
    }
    .van-picker__confirm{
      color: $theme-dark-label-color !important;
    }
    .van-picker__title{
      color: $theme-dark-label-color !important;
    }
    .van-picker__cancel{
      color: $theme-dark-label-color !important;
    }
  }
  .van-uploader__upload{
    background-color: $theme-dark-input-placeholder-color !important;
  }
  .van-calendar{
    background-color: $theme-dark-table-row-bg !important;
  }
  .text-infomation {
    color: $theme-dark-label-color !important;
  }
  .text-label-info {
    color: $theme-dark-label-color !important;
  }
  .badge.badge-light-secondary{
    color: $theme-dark-label-color !important;
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.nav-top-margin{
  margin-top: 60px !important;
}
.van-nav-bar__content {
  vertical-align: middle;
  height: 60px !important;
}
</style>
